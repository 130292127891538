
import { defineComponent, ref } from "vue";
import { useMutation, useQuery } from "@vue/apollo-composable";
import { getLink } from "@/utils/utils";
import { FIND_TAGS } from "../constants/gql/tag";
import { FIND_CFT_QUERY } from "../constants/gql/ctf";
import { ARRAY_OF_LEVEL } from "../constants/level";
import { ARRAY_OF_COUNTRY } from "../constants/country";
import { FIND_ONE_USER } from "../constants/gql/user";
import { Tag } from "../constants/types";
import { useRoute, useRouter } from "vue-router";
import Loader from "../components/Loader.vue";
import { SEND_MESSAGE_MUTATION } from "@/constants/gql/message";
import { useStore } from "vuex";

export default defineComponent({
  components: {
    Loader
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const router = useRouter();
    const username = route.params.username;
    const { result: user, loading } = useQuery(FIND_ONE_USER, {
      username
    });
    const isSendMessageOpen = ref(false);
    const textToSend = ref<string | null>(null);

    const { loading: ctfLoading, result: ctfResult } = useQuery(FIND_CFT_QUERY);
    const { loading: tagLoading, result: tagResult } = useQuery(FIND_TAGS);
    const { mutate: sendMessageMutation } = useMutation(SEND_MESSAGE_MUTATION);

    const findCtfById = (ctfId: string) => {
      const ctf = ctfResult.value.findCtf.find((el: any) => el.id === ctfId);

      return ctf.title;
    };

    const findTagColor = (tagName: string) => {
      const result = tagResult.value.findTags.find(
        (tag: Tag) => tag.name === tagName
      );

      return result.color;
    };

    const sendMessage = async () => {
      if (textToSend.value == null || textToSend.value.trim() == "") {
        return;
      }

      const sentMessage = await sendMessageMutation({
        input: {
          to: username,
          text: textToSend.value
        }
      });
      store.commit("addMessage", sentMessage.data.newMessage);
      isSendMessageOpen.value = false;
      router.push("/inbox/" + username);
    };

    return {
      findTagColor,
      findCtfById,
      sendMessage,
      getLink,
      user,
      username,
      arrayOfLevel: ARRAY_OF_LEVEL,
      arrayOfCountry: ARRAY_OF_COUNTRY,
      tagLoading,
      tagResult,
      ctfLoading,
      ctfResult,
      loading,
      textToSend,
      isSendMessageOpen
    };
  }
});
